import { config } from "@libs/config/config";
import React, { ReactNode } from "react";
import {
  DefaultValues,
  FormProvider,
  useForm,
  UseFormReturn
} from "react-hook-form";

import {
  IStackStyles,
  MessageBar,
  MessageBarType,
  Overlay,
  Stack
} from "@bps/fluent-ui";
import { HttpError } from "@bps/http-client";
import { DevTool } from "@hookform/devtools";

import { SubmitFormButtons, SubmitFormButtonsProps } from "./SubmitFormButtons";
import { ValidateCallback } from "@components/form/types/validate.types";
import { validationResolver } from "@components/form/validation/validation-resolver";

interface FormProps<FormValues extends object> extends SubmitFormButtonsProps {
  defaultValues: DefaultValues<FormValues>;
  onSubmit: (
    values: FormValues,
    form: UseFormReturn<FormValues>
  ) => Promise<void>;
  children: ReactNode;
  formStyles?: IStackStyles;
  buttonsRootStyles?: IStackStyles;
  hideButtons?: boolean;
  error?: HttpError | null;
  validate?: ValidateCallback<FormValues>;
}

/**
 * Form layout reusable component;
 * @param defaultValues
 * @param onSubmit
 * @param error
 * @param children
 * @param formStyles
 * @constructor
 */
/** */
export const Form = <FormValues extends object = object>({
  defaultValues,
  onSubmit,
  children,
  formStyles,
  hideButtons,
  error,
  validate,
  ...buttonsProps
}: FormProps<FormValues>) => {
  const form = useForm<FormValues>({
    defaultValues,
    mode: "onChange",
    resolver: validate
      ? (values: FormValues) => validationResolver(validate, values)
      : undefined
  });

  const errorMessage: string | undefined = error?.detail
    ? (error.detail as string)
    : error?.message;

  return (
    <>
      <FormProvider<FormValues> {...form}>
        <>
          <Stack
            as="form"
            onSubmit={form.handleSubmit(async values => {
              try {
                await onSubmit(values, form);
              } catch (e) {
                return e;
              }
            })}
            tokens={{ childrenGap: 16 }}
            styles={formStyles}
          >
            <Stack
              tokens={{ childrenGap: 8 }}
              grow
              styles={{ root: { position: "relative" } }}
            >
              {children}
              {form.formState.isSubmitting && (
                <Overlay styles={{ root: { zIndex: 100000 } }} />
              )}
            </Stack>
            {!hideButtons && <SubmitFormButtons {...buttonsProps} />}
            {errorMessage && (
              <MessageBar messageBarType={MessageBarType.error}>
                {errorMessage}
              </MessageBar>
            )}
          </Stack>
        </>
      </FormProvider>

      {/* ReactFormHooks Dev Tools https://react-hook-form.com/dev-tools */}
      {config.development && (
        <div id="react-hooks-dev-tools">
          <DevTool placement="bottom-right" control={form.control} />
        </div>
      )}
    </>
  );
};
